import Body from './body'
import '../styles/App.css';

function App() {
  return (
    <div className="App">
      <Body />
    </div>
  );
}

export default App;
